<template>
  <div class="container">
    <div class="box">Welcome</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  @import "./Welcome.scss";
</style>